@import "~antd/lib/style/themes/default.less";
@import "~antd/dist/antd.less";

// There are some major variables below,
// all less variables could be found in
// https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

// font
@font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
  Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

// base variables
@border-radius-base: 6px;
@card-padding-base: 2px;
@page-header-padding: 12px;

// colors
// @primary-color: #67be23;
@primary-color: #a3574f;
@error-color: #fa541c;
@text-color: #626262;

@light: #ffffff;
@light-grey: #ebebeb;
@dark-grey: #c5c5c5;
@light-blue: #e5f3fc;
@dark-blue: #CEDAE2;
@dark-green: #96a28b;
@light-green: #8DB6A6;
// sider
@layout-sider-background: #40403e;
@menu-bg: transparent;
@menu-item-color: #fff;
@menu-highlight-color: #fff;
@menu-inline-submenu-bg: inherit;
// @menu-item-color: #40403e;
// @menu-highlight-color: #40403e;
@menu-item-active-bg: transparent;
@menu-item-active-border-width: 0px;
@layout-trigger-background: rgba(0, 0, 0, 0.5);

.ant-menu-submenu > .ant-menu {
  background-color: @layout-sider-background;
}

//form
@label-color: @text-color;

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 0px;
}

.ant-form-item-label > label {
  font-weight: bold;
}

.ant-page-header {
  border-radius: @border-radius-base;
}

//table
.table-row-light {
  background: @light;
}
.table-row-light-blue {
  background: @light-blue;
}
.table-row-dark-grey {
  background: @dark-grey;
}
.table-row-light-grey {
  background: @light-grey;
}
.table-row-dark-blue {
  background: @dark-blue;
}
.table-row-dark-green {
  background: @dark-green;
}
.table-row-light-green {
  background: @light-green;
}
.table-row-hidden {
  height: 0;
  transition: height 0.3s linear;
}
// .table-row-hidden:hover {
//   // transform: scaleY(1);
// }
.ant-table.ant-table-small {
  .ant-table-tbody > tr > td {
    padding: 0px 4px;
  }
}
// transparent popover
.ant-popover-inner {
  background-color: #ffffff99;
}

// .ant-table-wrapper {
//   height: 100%;

//   .ant-spin-nested-loading {
//     height: 100%;

//     .ant-spin-container {
//       height: 100%;
//       display: flex;
//       flex-flow: column nowrap;

//       .ant-table {
//         flex: auto;
//         overflow: hidden;

//         .ant-table-container {
//           height: 100%;
//           display: flex;
//           flex-flow: column nowrap;

//           .ant-table-header {
//             flex: none;
//           }

//           .ant-table-body {
//             flex: auto;
//             overflow: scroll;
//           }
//         }
//       }

//       .ant-table-pagination {
//         flex: none;
//       }
//     }
//   }
// }


// disable changing color of ro on mouse hover
@table-row-hover-bg: inherit;

// general
.hidden-row {
  display: none;
}

// scrollBar
*{-ms-overflow-style: none;}
::-webkit-scrollbar{width:6px;border-left:1px solid #E6ECF8;}
::-webkit-scrollbar-thumb{background-color:@dark-grey;}

//button
.antd-btn-warning {
  background-color: #f0ad4e;
  border-color: #eea236;
  color: #fff;
}

.antd-btn-success {
  background-color: #5cb85c;
  border-color: #4cae4c;
  color: #fff;
}

.antd-btn-info {
  background-color: #5bc0de;
  border-color: #46b8da;
  color: #fff;
}

.antd-btn-danger {
  background-color: #d9534f;
  border-color: #d43f3a;
  color: #fff;
}

// timeline
.vis-grid.vis-odd{background:#E5F3FC}
.vis-badges {
	position: absolute;
	display: block;
	top: -6px;
	padding: 0px 4px;
	border: 1px solid #A9A9A9;
	border-radius:100%;
	background-clip: padding-box;
	// font:bold 8px \"Helvetica Neue\", sans-serif;
	color: transparent;
}

// .vis-item:before { display: block; content: "<span class='vis-badges' style='margin-left: 0px; right: 0px;background: none;'>.</span>"; }
// & .vis-item::before {
//     display: block; 
//   content: "<span class='vis-badges' style='margin-left: 0px; right: 0px;background: none;'>.</span>";

//   & .vis-item-overflow {
//     & .vis-item-content {
//       .vis-badges-white
//     }
//   }
// }
// .vis-badges-white:after { display: block; content: "<span class='vis-badges' style='margin-left: 0px; right: 0px;background: none;'>.</span>";}

// .vis-badges-white::after { display: block; content: '<span class="vis-badges" style="margin-left: 0px; right: 0px;background: none;">.</span>';}

.vis-badges-white {background: none}
.vis-badges-chartreuse {background: chartreuse}
.vis-badges-bordeaux {background: #6d071a}
